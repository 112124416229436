import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql, StaticQuery } from "gatsby"
import { withPreview } from "@prismicio/gatsby-source-prismic-graphql"
import TopSubNav from "../components/TopSubNav"
import ClientResultsContainer from "../components/ClientResultsContainer"

export const query = graphql`
  {
    prismic {
      allClient_resultss {
        edges {
          node {
            seo_page_title
            seo_page_description
            seo_h1
            page_title
            clients {
              client_logo
            }
            _meta {
              uid
            }
          }
        }
      }
      allClient_results(sortBy: meta_lastPublicationDate_DESC, last: 500) {
        edges {
          node {
            feature_image
            short_description
            title
            _meta {
              uid
              lastPublicationDate
            }
          }
        }
      }
    }
  }
`

const renderComponent = data => {
  const doc = data.prismic.allClient_resultss.edges.slice(0, 1).pop()
  if (!doc) return null

  const topScrollLinks = [
    {
      id: "Case_Studies",
      label: "Case Studies",
      isVisible: false,
    },
    {
      id: "Our_Clients",
      label: "Our Clients",
      isVisible: false,
    },
  ]

  const clientResults = data.prismic.allClient_results.edges

  const seoTitle =
    (doc.node &&
      doc.node.seo_page_title &&
      doc.node.seo_page_title[0] &&
      doc.node.seo_page_title[0].text) ||
    (doc.node &&
      doc.node.page_title &&
      doc.node.page_title[0] &&
      doc.node.page_title[0].text) ||
    ""
  const seoDescription =
    (doc.node &&
      doc.node.seo_page_description &&
      doc.node.seo_page_description[0] &&
      doc.node.seo_page_description[0].text) ||
    ""

  return (
    <Layout>
      <SEO title={seoTitle} description={seoDescription} />
      <TopSubNav
        title={doc.node.page_title[0].text}
        topScrollLinks={topScrollLinks}
      />
      <ClientResultsContainer
        clientResults={clientResults}
        ourClients={doc.node.clients}
        seoH1={doc.node.seo_h1}
      />
    </Layout>
  )
}

export default () => {
  return (
    <>
      <StaticQuery query={query} render={withPreview(renderComponent, query)} />
    </>
  )
}
