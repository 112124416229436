import React, { useContext } from "react"
import { Link } from "gatsby"
import VisibilitySensor from "react-visibility-sensor"
import { ActiveScrollContext } from "../hooks/ActiveScrollContext"
import { ContentSection } from "./ContentSection"
import styled from "styled-components"
import ArrowRight from "@material-ui/icons/ArrowRightAlt"
import { RichText } from "prismic-reactjs"

const LogoImagesContainer = styled("div")`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 480px) {
    flex-direction: column;
  }
`

const LogoImage = styled("div")`
  flex: 0 0 200px;
  height: 120px;
  margin: 0 0.5rem;
  margin-bottom: 5rem;
  position: relative;

  &::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-image: ${props =>
      props.imageUrl ? `url(${props.imageUrl})` : "none"};
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
  }

  @media screen and (max-width: 480px) {
    flex: 0 0 120px;
    width: 100%;
    margin: 1rem 0;
  }
`

const ClientResultsCardsContainer = styled("div")`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  @media screen and (max-width: 600px) {
    display: block;
  }

  .card-container-link {
    color: black;
    text-decoration: none;
    flex: 0 0 24%;
    margin-right: 0.5rem;
    margin-bottom: 1rem;
  }

  .card-container-link:nth-child(4n) {
    margin-right: 0;
  }
`

const ClientResultCard = styled("div")`
  align-self: stretch;
  background: white;
  border: solid 1px #e3e3e3;
  border-radius: 3px;
  transform: translateY(0);
  transition: all 0.25s ease;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr;
  -ms-grid-rows: 200px 225px auto;
  grid-template-columns: auto;
  grid-template-rows: 200px 225px auto;

  &:hover {
    transform: translateY(-0.5rem);
    box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.12);
  }

  @media screen and (max-width: 600px) {
    margin: 0;
    margin-bottom: 1rem;
    grid-template-rows: 200px auto auto;
  }

  .feature-image {
    -ms-grid-column: 1;
    -ms-grid-row: 1;
    grid-column: 1;
    grid-row: 1;
    position: relative;

    &::before {
      content: " ";
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-image: ${props =>
        props.imageUrl ? `url(${props.imageUrl})` : "none"};
      background-repeat: no-repeat;
      background-position: 50% 0;
      background-size: cover;
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;
    }
  }

  .content {
    padding: 1rem;
    -ms-grid-column: 1;
    -ms-grid-row: 2;
    grid-column: 1;
    grid-row: 2;
    overflow: hidden;

    h3 {
      font-size: 1.2rem;
    }

    p {
      font-size: 0.9rem;
    }
  }

  .footer {
    padding: 1rem;
    display: flex;
    justify-content: flex-end;
    -ms-grid-column: 1;
    -ms-grid-row: 3;
    grid-column: 1;
    grid-row: 3;

    .read-more-link {
      flex: 0 0 auto;
      display: flex;
      align-items: center;
      text-decoration: none;
      color: #0099cb;
    }
  }
`

const ClientResultsContainer = ({ ourClients, clientResults, seoH1 }) => {
  const [, setActiveItems] = useContext(ActiveScrollContext)
  function onChange(isVisible, id) {
    setActiveItems({ id, isVisible })
  }
  return (
    <>
      <VisibilitySensor
        onChange={isVisible => onChange(isVisible, "Case_Studies")}
        key={"Case_Studies"}
        partialVisibility="top"
      >
        <ContentSection>
          <div className="inner-container">
            {seoH1 && RichText.render(seoH1)}
            <div className="anchor" id="Case_Studies" />
            {clientResults && (
              <ClientResultsCardsContainer>
                {clientResults.map(r => (
                  <Link
                    to={`/client-result/${r.node._meta.uid}`}
                    className="card-container-link"
                  >
                    <ClientResultCard
                      imageUrl={
                        r.node.feature_image && r.node.feature_image.url
                          ? r.node.feature_image.url
                          : "https://images.prismic.io/crgwebsite/a5754dbe-1713-4a19-a49a-20993bf5f6e6_crg-empty-image-logo.svg?auto=compress,format"
                      }
                    >
                      <div className="feature-image"></div>
                      <div className="content">
                        <h3>{RichText.asText(r.node.title)}</h3>
                        <p>{RichText.asText(r.node.short_description)}</p>
                      </div>
                      <div className="footer">
                        <Link
                          to={`/client-result/${r.node._meta.uid}`}
                          className="read-more-link"
                        >
                          <span>See Case Study</span> <ArrowRight />
                        </Link>
                      </div>
                    </ClientResultCard>
                  </Link>
                ))}
              </ClientResultsCardsContainer>
            )}
          </div>
        </ContentSection>
      </VisibilitySensor>
      <VisibilitySensor
        onChange={isVisible => onChange(isVisible, "Our_Clients")}
        key={"Our_Clients"}
        partialVisibility="top"
      >
        <ContentSection>
          <div className="inner-container">
            <div className="anchor" id="Our_Clients" />
            <h4>Our Clients</h4>
            {ourClients && (
              <LogoImagesContainer>
                {ourClients.map(c => (
                  <LogoImage imageUrl={c.client_logo.url}></LogoImage>
                ))}
              </LogoImagesContainer>
            )}
          </div>
        </ContentSection>
      </VisibilitySensor>
    </>
  )
}

export default ClientResultsContainer
